export const HOTEL_REQUESTGRN = "HOTEL_REQUESTGRN";
export const HOTEL_SUCCESSGRN = "HOTEL_SUCCESSGRN";

export const HOTEL_REQUESTGRNFEW = "HOTEL_REQUESTGRNFEW";
export const HOTEL_SUCCESSGRNFEW = "HOTEL_SUCCESSGRNFEW";

export const HOTEL_SINGLE_DETAIL_REQUEST = "HOTEL_SINGLE_DETAIL_REQUEST";
export const HOTEL_SINGLE_SUCCESS = "HOTEL_SINGLE_SUCCESS";

export const HOTEL_ROOMSELECT_REQUEST = "HOTEL_ROOMSELECT_REQUEST";
export const HOTEL_ROOMSELECT_SUCCESS = "HOTEL_ROOMSELECT_SUCCESS";

export const HOTEL_GALLERY_REQUEST = "HOTEL_GALLERY_REQUEST";
export const HOTEL_GALLERY_SUCCESS = "HOTEL_GALLERY_SUCCESS";

export const HOTEL_B0OKROOM_REQUESTGRN = "HOTEL_B0OKROOM_REQUESTGRN";
export const HOTEL_B0OKROOM_SUCCESSGRN = "HOTEL_B0OKROOM_SUCCESSGRN";

export const CLEAR_HOTEL_REDUCER_GRN = "CLEAR_HOTEL_REDUCER_GRN";
export const CLEAR_HOTEL_BLOCK_ROOM = "CLEAR_HOTEL_BLOCK_ROOM";

export const CLEAR_ONLYHOTEL_GRN = "CLEAR_ONLYHOTEL_GRN";
export const CLEAR_HOTEL_ROOMGALLERY_GRN = "CLEAR_HOTEL_ROOMGALLERY_GRN";
export const CLEAR_HOTEL_ROOM_SELECT = "CLEAR_HOTEL_ROOM_SELECT";
export const CLEAR_HOTEL_ROOM_ALL = "CLEAR_HOTEL_ROOM_ALL";
