export const GET_SEARCH_PACKAGE_DATA = "GET_SEARCH_PACKAGE_DATA";
export const SET_SEARCH_PACKAGE_DATA = "SET_SEARCH_PACKAGE_DATA";
export const GET_SEARCH_PACKAGE_DATA_CATEGORY =
  "GET_SEARCH_PACKAGE_DATA_CATEGORY";
export const SET_SEARCH_PACKAGE_DATA_CATEGORY =
  "SET_SEARCH_PACKAGE_DATA_CATEGORY";
export const GET_SEARCH_PACKAGE_DATA_COUNTRY =
  "GET_SEARCH_PACKAGE_DATA_COUNTRY";
export const SET_SEARCH_PACKAGE_DATA_COUNTRY =
  "SET_SEARCH_PACKAGE_DATA_COUNTRY";
export const GET_SEARCH_PACKAGE_DATA_BUDGET = "GET_SEARCH_PACKAGE_DATA_BUDGET";
export const SET_SEARCH_PACKAGE_DATA_BUDGET = "SET_SEARCH_PACKAGE_DATA_BUDGET";

export const GET_ALL_PACKAGE_DATA = "GET_ALL_PACKAGE_DATA";
export const SET_ALL_PACKAGE_DATA = "SET_ALL_PACKAGE_DATA";

export const GET_THEME_PACKAGE_DATA = "GET_THEME_PACKAGE_DATA";
export const SET_THEME_PACKAGE_DATA = "SET_THEME_PACKAGE_DATA";
export const CLEAR_PACKAGE_DATA = "CLEAR_PACKAGE_DATA";
